.p-table td {
  padding-bottom: 14px !important;
  padding-top: 14px !important;
  font-size: 12px !important;
  border: none !important;
  background: none;
  font-weight: 400 !important;
}

.p-table th {
  padding-bottom: 14px !important;
  padding-top: 14px !important;
  font-size: 12px !important;
  color: #898989 !important;
  font-weight: 400 !important;
  border: none !important;
  background: none !important;
}

.app-table,
.filter-table,
.extra-filter-table,
.report-table {
  background: none;
}

.app-modal .ant-modal-content {
  padding: 24px;
  border-radius: 0px;
}

.card-shadow-sm {
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.card-shadow {
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.card-shadow-lg {
  box-shadow: 0px 25px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-switch .ant-switch-inner {
  background: #ffffff;
}

.completion-modal .ant-modal-content {
  padding: 24px;
  border-radius: 0px;
  height: 442px;
  .ant-modal-body {
    height: 100%;
  }
}

.default-page-height {
  height: calc(100vh - 160px);
}

.d-table td {
  padding: 16px 24px !important;
  font-size: 14px !important;
  border: none !important;
  background: none;
  font-weight: 500 !important;

  @media (max-width: 1024px) {
    padding: 14px 10px !important;
    font-size: 12px !important;
  }
}

.d-table.add-pointer td {
  cursor: pointer !important;
}

.d-table th {
  padding: 14px 24px !important;
  font-size: 14px !important;
  color: #434343 !important;
  font-weight: 500 !important;
  border: none !important;
  background: #f9f9f9 !important;

  @media (max-width: 1024px) {
    padding: 14px 10px !important;
    font-size: 12px !important;
  }
}

.d-table th:first-child {
  border-bottom-left-radius: 10px;
}

.d-table th:last-child {
  border-bottom-right-radius: 10px;
}

.app-table {
  border-radius: 10px;
  height: calc(100vh - 15%);
}

.filter-table {
  border-radius: 10px;
  height: calc(100vh - 20%);
}

.extra-filter-table {
  border-radius: 10px;
  height: calc(100vh - 25%);
}

.report-table {
  border-radius: 10px;
  height: calc(100vh - 30%);
}

.product-table {
  border-radius: 10px;
  height: 60vh;
}

.delete-no-confirmation {
  transition: all 0.3s;
  .first {
    transition: all 0.3s;
    display: block;
  }
  .last {
    transition: all 0.3s;
    display: none;
  }
}

.delete-confirmation {
  transition: all 0.3s;
  width: 100%;
  justify-content: space-between;
  .first {
    transition: all 0.3s;
    display: none;
  }
  .last {
    transition: all 0.3s;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #820909;
    border-radius: 8px;

    svg {
      path {
        stroke: #ffffff;
      }
    }
  }
}

.barcode-in-drawer {
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.location-purchase-orders-search {
  left: 2% !important;
}

.drawer-content-height {
  height: calc(100vh - 360px);
  position: relative;
  overflow-y: auto;
}

.drawer-content-height-no-step {
  height: calc(100vh - 240px);
  position: relative;
  overflow-y: auto;
}

.status {
  padding: 6px 10px;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.12px;
  font-weight: 400;
  // width: min-content;
  min-width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active,
  &.completed,
  &.closed,
  &.instock {
    background: #f5fef7;
    color: #09821a;
  }

  &.pending,
  &.in-progress,
  &.inactive,
  &.opened {
    background: #fffaf5;
    color: #fd8e0c;
  }

  &.failed {
    background: #fef5f5;
    color: #820909;
  }
}

.customFieldIcon {
  height: 16px !important;
  top: 44% !important;
  left: 10% !important;
}

.pricingFieldIcon {
  height: 16px !important;
  top: 44% !important;
  left: 2% !important;
}

.smallPricingFieldIcon {
  height: 16px !important;
  top: 32% !important;
  left: 6% !important;
}

.po-confirm-details {
  // height: calc(100vh - 70%);
  // overflow-y: auto;
}

.po-confirm-details-check span {
  transform: scale(1.2);
}

.po-confirm-details-check {
  padding-right: 10px;
}

.alerts-btn {
  button {
    padding: 6px 14px;
    border-radius: 42px;
    background: #fff7f5;
    border: 1px solid #fc622f;
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 17.64px;
    color: #fc622f;

    &.inactive {
      background: #f7f7f7;
      border: none;
      color: #c8c8c8;
    }
  }
}

svg.product-properties-separator {
  height: 4px;
  width: 4px;
  circle {
    fill: #f5f5f5;
  }
}

svg.product-separator {
  height: 4px;
  width: 4px;
  circle {
    fill: #2763e9;
  }
}

svg.product-separator-secondary {
  height: 4px;
  width: 4px;
  circle {
    fill: #09821a;
  }
}

.triggers {
  input {
    width: 50px;
    height: 32px;
    border: 1px solid #efefef;
    text-align: center;
    border-radius: 4px;
  }
}

.blue-evelope {
  svg {
    path {
      stroke: #2763e9;
    }
  }
}

.pink-evelope {
  svg {
    path {
      stroke: #e927d6;
    }
  }
}

.orange-evelope {
  svg {
    path {
      stroke: #fc622f;
    }
  }
}

.green-evelope {
  svg {
    path {
      stroke: #13a945;
    }
  }
}

.report-export-btn {
  padding: 12px 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  background: #f5fef7;
  color: #09821a;
  svg {
    path {
      stroke: #09821a;
    }
  }

  &:disabled {
    background: #a3a3a3;
    color: #ffffff;
    border: none;
    cursor: not-allowed;
    svg {
      path {
        stroke: #ffffff;
      }
    }
  }
}

.custom-property-section {
  height: calc(100vh - 150px);
}
