.container {
  width: 100%;
  @apply mx-auto;
  @apply px-5;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.App {
  text-align: center;
}

.ant-carousel {
  width: 100% !important;
  height: 100% !important;
}

.form-field_input_0 {
  padding: 8px 10px 8px 14px !important;
  outline-width: 0 !important;
  border: 1px solid #e4e6eb;
  border-radius: 0px;
  width: 100%;
  transition: all 200ms ease-in-out;
  background: transparent;
  color: #01061f;
  font-weight: 500;
  font-size: 12px;
}

.form-field_input_0 .ant-input {
  background: transparent;
  padding-left: 10px !important;
}

.form-field_input_0:hover,
.form-field_input_0:focus,
.form-field_input_0:active {
  border: 1px solid #fc622f;
  outline-width: 0 !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-picker
  .ant-picker-input
  > input {
  color: #ffffff;
}

.form-field_input_2 {
  padding: 12px 10px 12px 10px !important;
  outline-width: 0 !important;
  border: 1px solid #efefef;
  border-radius: 10px;
  width: 100%;
  transition: all 200ms ease-in-out;
  background: #ffffff;
  color: #555555;
  font-weight: 500 !important;
  font-size: 14px;

  &.extra {
    padding-left: 32px !important;
  }

  &.slim {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}

.form-waiting {
  padding: 4px 10px 4px 4px !important;
  outline-width: 0 !important;
  border: none;
  border-radius: 18px 0px 0px 18px;
  outline: none !important;
  width: 100%;
  transition: all 200ms ease-in-out;
  background: #121d42;
  color: #ffffff;
  font-weight: 400 !important;
  font-size: 14px;
}

.form-field_input_2:hover,
.form-field_input_2:focus,
.form-field_input_2:active {
  border: 1px solid #fc622f;
  outline-width: 0 !important;
}

.form-waiting:hover,
.form-waiting:focus,
.form-waiting:active {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
  outline-width: 0 !important;
  background: #121d42;
}

.form-field_input_2:disabled {
  background: #fafbff;
  font-size: 14px;
  color: #000000;
  cursor: not-allowed;
}

.form-field_input_3 {
  padding: 12px 10px 12px 10px !important;
  outline-width: 0 !important;
  border: 1px solid #efefef;
  border-radius: 10px;
  width: 100%;
  transition: all 200ms ease-in-out;
  background: #ffffff;
  color: #01061f;
  font-weight: 600 !important;
  font-size: 14px;
  height: 48px;
  .ant-select-selection-item,
  .ant-select-selector {
    background: #ffffff !important;
  }
  .ant-select-selection-placeholder {
    color: #898989 !important;
    font-size: 14px;
    font-weight: 400 !important;
  }
}

.overview-field {
  padding: 8px 10px 8px 10px !important;
  outline-width: 0 !important;
  border: 1px solid #efefef;
  border-radius: 10px;
  width: 100%;
  transition: all 200ms ease-in-out;
  background: #ffffff;
  color: #01061f;
  font-weight: 600 !important;
  font-size: 10px;
  height: 36px;
  .ant-select-selection-item,
  .ant-select-selector {
    background: #ffffff !important;
  }
  .ant-select-selection-placeholder {
    color: #898989 !important;
    font-size: 14px;
    font-weight: 400 !important;
  }
}

.auth-input {
  .form-field_input_3 {
    padding-left: 25% !important;
  }

  .space-left {
    padding-left: 25% !important;
  }

  .search {
    padding-left: 7% !important;
  }
}

.form-field_input_3:hover,
.form-field_input_3:focus,
.form-field_input_3:active {
  border: 1px solid #fc622f;
  outline-width: 0 !important;
}

.overview-field:hover,
.overview-field:focus,
.overview-field:active {
  border: 1px solid #fc622f;
  outline-width: 0 !important;
}

.form-field_input_0::placeholder,
.form-field_input_1::placeholder,
.form-field_input_2::placeholder,
.form-field_input_3::placeholder,
.ant-picker-input input::placeholder {
  color: #898989 !important;
  font-size: 14px;
  font-weight: 400 !important;
}

.overview-field::placeholder {
  color: #898989 !important;
  font-size: 10px;
  font-weight: 400 !important;
}

.overview-field {
  .ant-select-selector {
    border: none !important;
    padding: 0px !important;
    height: 18px !important;
    outline: none;
  }
}

.form-waiting::placeholder {
  color: #c8c8c8 !important;
  font-weight: 400 !important;
}

.ant-form-item-explain-error {
  font-size: 12px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fc622f !important;
  border-color: #fc622f !important;
}

.echarts-for-react {
  width: 100% !important;
}

.confirm-modal {
  width: 391px !important;
  .ant-modal-content {
    background: #ffffff;
  }
}

.transparent-bg {
  background: transparent !important;
}

.ant-select-selector {
  border: none !important;
  padding: 0px !important;
  height: 24px !important;
  outline: none;
}

ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-pagination-item-active {
  background: #f9f5ff !important;
  color: #7f56d9 !important;
  border: none !important;
}

.ant-pagination-item-active a {
  color: #7f56d9 !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-pagination.ant-pagination {
  display: flex;
  justify-content: center;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-thead
  > tr
  > th {
  background: #f2f2f2 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #657081 !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  border-bottom: 1px dashed #e6e7ea !important;
  color: #21252b !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}

:where(.css-dev-only-do-not-override-ixblex) a:hover {
  color: #f9f5ff;
}

:where(.css-dev-only-do-not-override-ixblex) a[disabled] {
  color: #828282;
}

.ant-picker-ok button,
.ant-picker-ok button:hover,
.ant-picker-ok button:focus {
  color: #ffffff !important;
  background: #dca437 !important;
}

:where(.css-dev-only-do-not-override-ixblex).ant-picker-status-error.ant-picker,
:where(
    .css-dev-only-do-not-override-ixblex
  ).ant-picker-status-error.ant-picker:not([disabled]):hover {
  background: #141416 !important;
}

:where(.css-dev-only-do-not-override-djtmh8).ant-picker-outlined {
  background: #f2f2f2 !important;
}

.search-field {
  width: 280px;
  :where(.css-dev-only-do-not-override-djtmh8).ant-form-item {
    margin: 0px;
    width: 280px;
  }
  input {
    background: #ffffff;
    padding: 10px 10px 10px 36px !important;
    border-radius: 8px !important;
    border: 1px solid #efefef;
  }
}

.ant-dropdown .ant-dropdown-menu {
  padding: 15px !important;
  row-gap: 15px;
  display: flex;
  flex-direction: column;
}

.money-dd {
  padding: 0px !important;
}

:where(.css-dev-only-do-not-override-1kuana8).ant-table-wrapper .ant-table {
  background: none !important;
}

.nav-route {
  .ant-collapse-header {
    padding: 0px 0px 0px 12px !important;
    display: flex !important;
    align-items: center !important;

    .ant-collapse-header-text {
      display: contents;
    }

    .ant-collapse-expand-icon {
      padding: 0px !important;
    }
  }

  .quic-dot-wrapper {
    padding-left: 2.5rem;
  }
}

.mobile-nav-route {
  .ant-collapse-header {
    padding: 0px 0px 0px 12px !important;
    display: flex !important;
    align-items: center !important;

    .ant-collapse-header-text {
      display: contents;
    }

    .ant-collapse-expand-icon {
      padding: 0px !important;
    }
  }

  .ant-collapse-content {
    .ant-collapse-content-box {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .quic-dot-wrapper {
    padding-left: 0rem;

    .quic-dot {
      display: none;
    }
  }
}

.ant-drawer-content-wrapper {
  width: 640px !important;
  right: 20px !important;
  height: calc(100vh - 40px) !important;
  margin: auto !important;

  @media (max-width: 425px) {
    right: 0px !important;
  }
}

.app-drawer {
  &.ant-drawer-content {
    border-radius: 16px;
  }
  .ant-drawer-body {
    padding: 28px !important;
    position: relative;
  }
}

:where(.css-dev-only-do-not-override-11lehqq).ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice {
  padding: 10px 12px !important;
  background: #f5fef7 !important;
  border-radius: 10px;
}

.ant-notification-notice-message {
  margin: 0px !important;
}

// table styles

.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100% !important;
}

.ant-spin-container {
  display: flex;
  flex-direction: column;
}

.ant-pagination-total-text {
  margin-right: auto !important;
  color: #898989;
  font-size: 16px;
  @media (max-width: 425px) {
    display: none !important;
  }
}

.ant-table {
  height: calc(100% - 72px) !important;
  overflow-y: scroll;
}

.ant-pagination {
  align-items: center !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  height: auto !important;
}

.ant-pagination-item {
  padding: 8px 12px;
  font-size: 16px;
  height: auto !important;
  border-radius: 10px !important;
  a {
    color: #c8c8c8 !important;
  }
}

.ant-pagination-item-active {
  padding: 8px 12px;
  font-size: 16px;
  color: #ffffff !important;
  background: #2763e9 !important;
  height: auto !important;
  border-radius: 10px !important;
  a {
    color: #ffffff !important;
  }
}

.app-pagination-btn {
  padding: 8px 12px;
  font-size: 16px;
  color: #2763e9 !important;
  font-weight: 400px;
  background: #f6f8fe;
  border-radius: 10px;

  &.prev {
    margin-right: 10px;
  }

  &.next {
    margin-left: 10px;
  }

  &:disabled {
    background: #f9f9f9;
    color: #c8c8c8 !important;
    cursor: not-allowed !important;
  }
}

.custom-collpse-sm {
  border: none !important;
  background: #ffffff !important;

  .ant-collapse-header {
    border-radius: 8px !important;
    border: 1px solid #f6f6f6 !important;
    background: #ffffff !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .ant-collapse-content {
    border: none !important;

    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }

  &.error {
    .ant-collapse-header {
      border: 1px solid #ff4d4f !important;
    }
  }
}

.custom-collpse-md {
  border: none !important;
  background: #fcfcfcfa !important;

  .ant-collapse-header {
    border-radius: 8px !important;
    border: none !important;
    background: #f7f7f7 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
  }

  .ant-collapse-content {
    border: none !important;

    .ant-collapse-content-box {
      padding: 0px !important;
      background: #fcfcfcfa !important;
    }
  }
}

.btn_loading_spinner.ant-spin .ant-spin-dot i {
  background-color: #ffffff;
}

.PhoneInputInput {
  outline: none;
  border: none;
  &::placeholder {
    color: #898989 !important;
    font-size: 14px;
    font-weight: 400 !important;
  }
}

.ant-pagination-options {
  order: -1;
  margin-right: 20px;

  .ant-pagination-options-size-changer {
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
  }

  .ant-select-selector {
    border-radius: 10px !important;
    padding: 0 10px !important;
    background: #f6f8fe !important;
    height: 100% !important;

    input {
      height: 100% !important;
    }
  }
}

.ant-checkbox-inner:after {
  background-color: #fc622f !important;
}

.ant-upload-select {
  button {
    &:hover {
      border-color: #fc622f !important;
      color: #fc622f !important;
    }
  }
}

.drag-upload {
  padding: 0px !important;

  .ant-upload {
    padding: 0px !important;
    border: none !important;
    border-radius: 20px !important;
    margin-top: 24px;
    background: none;
    text-align: left;
  }
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner::after {
  transform: scale(0.6);
  background: #09821a;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #09821a;
  background-color: transparent;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.global-loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.user-management-tab {
  .ant-tabs-tab {
    padding: 4px 0;
  }

  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: #c8c8c8;
    }
    .ant-tabs-tab-btn:hover {
      color: #fc622f;
    }
    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: #000000;
      }
    }
  }
  .ant-tabs-ink-bar {
    background: #fc622f;
  }
}

.product-checkbox {
  width: 100%;
  padding: 14px;
  border: 1px solid #f6f6f6;

  .uom-label {
    font-size: 14px;
    font-weight: 500;
  }

  span:last-child {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ant-checkbox,
  .ant-checkbox-checked,
  .ant-checkbox-inner {
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0d821a !important;
    border-color: #0d821a !important;
  }

  .ant-checkbox-inner:after {
    background-color: #0d821a !important;
    transform: rotate(45deg) scale(1.2) translate(-20%, -60%);
  }
}

.force-wrap {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.slick-list,
.slick-slide {
  border-radius: 20px;
}

.slick-dots li {
  width: 100px !important;
  height: 5px !important;
}

.slick-dots li.slick-active button {
  background: #2763e9 !important;
  width: 100px;
  height: 5px !important;
  border-radius: 4px;
}

.slick-dots li button {
  background: #d6d6d6;
  width: 100px;
  height: 5px !important;
  border-radius: 4px;
  opacity: 0.7;
}

.slick-dots {
  bottom: 50px !important;
}

.slick-dots li button:hover {
  opacity: 1 !important;
}

.filter-modal {
  width: fit-content !important;
}

:where(.css-dev-only-do-not-override-qnu6hi).ant-modal-root .ant-modal-wrap {
  z-index: 10000;
}

.pricing-checkbox {
  width: 100%;

  .pricing-label {
    font-size: 14px;
    font-weight: 500;
    color: #898989;
  }

  span:last-child {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ant-checkbox,
  .ant-checkbox-checked,
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 6px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #ffffff !important;
    border-color: #667185 !important;
  }

  .ant-checkbox-inner:after {
    background-color: #ffffff !important;
    transform: rotate(45deg) scale(1.2) translate(-40%, -50%);
  }

  .ant-checkbox-inner:after {
    border-right: 2px solid #667185;
    border-bottom: 2px solid #667185;
  }
}
