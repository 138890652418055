.button_primary {
  @apply flex;
  @apply items-center;
  color: #ffffff;
  @apply duration-300;
  @apply bg-primary-500;
  @apply justify-center;
  @apply font-medium;
  border-radius: 4px;

  &:disabled {
    @apply bg-gray-300;
    cursor: not-allowed;
  }
}

.button_secondary {
  @apply flex;
  @apply items-center;
  color: #ffffff;
  @apply duration-300;
  @apply bg-secondary-500;
  @apply justify-center;
  @apply font-medium;
  border-radius: 4px;

  &:hover {
    @apply bg-secondary-600;
  }

  &:disabled {
    @apply bg-gray-300;
    cursor: not-allowed;
  }
}

.btn_bordered {
  border-width: 1px;
  @apply bg-white;
  @apply text-primary-500;
  @apply border-primary-500;
}

.nav-bar-floated {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6px);
}

.dark_rainbow_border {
  position: relative;
  margin: 5px;
  display: inline-block;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-weight: 400;
  transition: all 200ms ease-in-out;

  &:hover {
    font-weight: 500;
  }
}

.dark_rainbow_border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 42px;
  padding: 1px; /* control the border thickness */
  background: linear-gradient(90deg, #2763e9 0%, #ec8cda 50.93%, #fc622f 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.light_rainbow_border {
  position: relative;
  margin: 5px;
  display: inline-block;
  color: #2763e9;
  display: flex;
  align-items: center;
  font-weight: 400;
  transition: all 200ms ease-in-out;

  &:hover {
    font-weight: 500;
  }
}

.light_rainbow_border::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 42px;
  padding: 1px; /* control the border thickness */
  background: linear-gradient(90deg, #2763e9 0%, #ec8cda 50.93%, #fc622f 100%);
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.waiting-list {
  @apply bg-primary-600;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in-out;
  border-radius: 18px;
  padding: 8px;

  .ant-form-item {
    margin-bottom: 0px;
    width: 100%;
  }

  &:hover {
    border: 0.1px solid #ffffff;
    outline-width: 0 !important;
  }

  button {
    background: linear-gradient(
      180deg,
      #2763e9 0%,
      #195bef 52.55%,
      #144ac2 100%
    );
    color: #ffffff;
    box-shadow: 0px 21px 13.3px 0px #9997ff40 inset;
    width: 148px;
    height: 42px;
    border-radius: 10px;
    border: 1px 0px 0px 0px;
  }
}

.auth-input {
  position: relative;
  img:first-child {
    position: absolute;
    top: 28%;
    left: 3%;
    z-index: 20;
    height: 20px;
  }
  img:last-child {
    position: absolute;
    top: 28%;
    right: 5%;
    z-index: 20;
    height: 20px;
    cursor: pointer;
  }
}

.page-header {
  margin: 0px;
}

.pass-eye {
  height: 20px;
  position: absolute;
  top: 16px;
  bottom: 0;
  right: 16px;
  cursor: pointer;
}

.app-stepper {
  width: 268px;

  .app-steppers {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .app-step-one-link {
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, #2763e9 50%, #fafafa 50%);
    }

    .app-step-two-link {
      width: 100%;
      height: 2px;
      background: linear-gradient(to right, #2763e9 50%, #2763e9 50%);
    }
  }

  .app-steppers-label {
    margin-top: 12px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.table-actions {
  .table-action-btns {
    button {
      padding: 6px 12px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      &.import-btn {
        background: #fff7f5;
        color: #fc622f;
        svg {
          path {
            stroke: #fc622f;
          }
        }
      }

      &.export-btn {
        background: #f5fef7;
        color: #09821a;
        svg {
          path {
            stroke: #09821a;
          }
        }
      }

      &.filter-btn {
        background: #f6f8fe;
        color: #2763e9;
        svg {
          path {
            stroke: #2763e9;
          }
        }
      }

      &.delete-btn {
        border: 1px solid #820909;
        color: #820909;
        svg {
          path {
            stroke: #820909;
          }
        }
      }

      &:disabled {
        background: #a3a3a3;
        color: #ffffff;
        border: none;
        cursor: not-allowed;
        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }
}

.preloader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

svg.default-check {
  height: 24px;
  width: 24px;
  path {
    fill: #09821a;
  }
}

.vat-entry {
  .ant-form-item {
    margin: 0px !important;
  }

  #includeVat_help {
    display: none;
  }
}
